import React from 'react';
import { Link } from 'react-router-dom';

const Header = (props) => {

	const { language } = props;

	return (

		<div className="header col-sm-12">
			<div className="header-title__container">
				<h1 className="header-title__text">
					<Link to="/">
						{(language === "en") ? "Celebrating Fred" : "Célébrer Fred"}
					</Link>
				</h1>
			</div>
		</div>

	)
}

export default Header