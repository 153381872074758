import React from 'react';
import { assetsPath } from '../cockpit.js';
import { motion, AnimatePresence } from "framer-motion";
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

const IntroBox = (props) => {

	const { pathname, introText, language, introActive, setIntroActive } = props;

	if (introText) {

		return (
			<AnimatePresence>
		    {((introActive || pathname === "/intro") && pathname !== "/share" && pathname !== "/help") && (
		      <motion.div
		      	className="intro-box"
		        initial={{ opacity: 0 }}
		        animate={{ opacity: 1 }}
		        exit={{ opacity: 0 }}
		      >

		      	<div className="intro-text__container">

								{(language === "en") ? introText.en ? parse(introText.en) : '' : introText.fr ? parse(introText.fr) : ''}

		      			<div
				      		to="/"
				      		className="intro-link__button"
				      	>
				      			<Link
				      				to="/"
				      				className="intro-link__text"
				      				onClick={() => {setIntroActive(false)}}
				      				>
				      				{ (language === "en") ? "Enter" : "Entrer"}
				      			</Link>
				      	</div>
		      	</div>

						<div className="intro-image__container">
							{
								introText.image &&
								<img src={assetsPath + introText.image.path} alt={introText.image.title} className="intro-image" />
							}
						</div>

		      </motion.div>
		    )}
		  </AnimatePresence>
		);

	} else {
		return null;
	}



}

export default IntroBox