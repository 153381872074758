import React, {Fragment, useState} from 'react'
import { assetsPath } from '../cockpit.js';
import parse from 'html-react-parser';
import ReactPlayer from 'react-player';

const characterLimit = 800;

const ImagePostCard = (props) => {

	const { post, activeCategory, language } = props;
	const [textExpanded, setTextExpanded] = useState(false);

	if (activeCategory === "all" || activeCategory === "images") {

		if (post.message.length < characterLimit || textExpanded) {
			return (
				<div className="image-post__container">
					<img src={assetsPath + post.image.path} alt="" className="image-post__image" />
					<pre className="post__text">{post.message}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(false)}}
					>{post.message.length > characterLimit ? (language === "en" ? "Close" : "Fermer") : ""}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		} else {
			return (
				<div className="image-post__container">
					<img src={assetsPath + post.image.path} alt="" className="image-post__image" />
					<pre className="post__text">{post.message.substring(0, characterLimit) + "..."}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(true)}}
					>{(language === "en") ? "Read more" : "Lire la suite"}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		}

	} else {
		return null
	}
}


const TextPostCard = (props) => {

	const { post, activeCategory, language } = props;
	const [textExpanded, setTextExpanded] = useState(false);


	if (activeCategory === "all" || activeCategory === "text") {

		if (post.message.length < characterLimit || textExpanded) {
			return (
				<div className="text-post__container">

					<pre className="post__text">{post.message}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(false)}}
					>{post.message.length > characterLimit ? (language === "en" ? "Close" : "Fermer") : ""}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		} else {
			return (
				<div className="text-post__container">

					<pre className="post__text">{post.message.substring(0, characterLimit) + "..."}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(true)}}
					>{(language === "en") ? "Read more" : "Lire la suite"}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		}
	} else {
		return null
	}

}

const VideoPostCard = (props) => {

	const { post, activeCategory, language } = props;
	const [textExpanded, setTextExpanded] = useState(false);

	if (post.video_file) {
		if (post.video_file.toLowerCase().indexOf('.mov') > -1) {
			return null;
		}
	}

	if ((activeCategory === "all" || activeCategory === "videos")) {
		return (
			<div className="video-post__container">
				<ReactPlayer
					className="video-post__video"
					url={post.video_file ? (assetsPath + post.video_file) : post.video_url}
					width='100%'
					height={post.video_file ? '100%' : '300px'}
					playsinline={true}
					loop={false}
					controls={true}
				/>
				{
					post.message && post.message !== '' &&
					(post.message.length < characterLimit || textExpanded === true) ?
						<Fragment>
							<pre className="post__text">{post.message}</pre>
							<p className="post__expand"
							onClick={() => {setTextExpanded(false)}}
							>{post.message.length > characterLimit ? (language === "en" ? "Close" : "Fermer") : ""}</p>
						</Fragment>
						:
						<Fragment>
							<pre className="post__text">{post.message.substring(0, characterLimit) + "..."}</pre>
							<p className="post__expand"
							onClick={() => {setTextExpanded(true)}}
							>{(language === "en") ? "Read more" : "Lire la suite"}</p>
						</Fragment>
				}
				<p className="post__author">{post.name}</p>
			</div>
		)
	} else {
		return null
	}
}

const PlaylistPostCard = (props) => {

	const { post, activeCategory, language } = props;
	const [textExpanded, setTextExpanded] = useState(false);

	if(activeCategory === "all" || activeCategory === "sounds") {
		if (post.message.length < characterLimit || textExpanded) {
			return (
				<div className="playlist-post__container">
					{
						post.playlist_embed &&
							<span>{parse(post.playlist_embed)}</span>
					}
					<pre className="post__text">{post.message}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(false)}}
					>{post.message.length > characterLimit ? (language === "en" ? "Close" : "Fermer") : ""}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		} else {
			return (
				<div className="playlist-post__container">
					{
						post.playlist_embed &&
							<span>{parse(post.playlist_embed)}</span>
					}
					<pre className="post__text">{post.message.substring(0, characterLimit) + "..."}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(true)}}
					>{(language === "en") ? "Read more" : "Lire la suite"}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		}
	} else {
		return null
	}
}

const AudioPostCard = (props) => {

	const { post, activeCategory, language } = props;
	const [textExpanded, setTextExpanded] = useState(false);

	if(activeCategory === "all" || activeCategory === "sounds") {
		if (post.message.length < characterLimit || textExpanded) {
			return (
				<div className="audio-post__container">
					<audio controls className="audio-post__player">
					  <source src={assetsPath + post.sound} type="audio/mp3"/>
					</audio>
					<pre className="post__text">{post.message}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(false)}}
					>{post.message.length > characterLimit ? (language === "en" ? "Close" : "Fermer") : ""}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		} else {
			return (
				<div className="audio-post__container">
					<audio controls className="audio-post__player">
					  <source src={assetsPath + post.sound} type="audio/mp3"/>
					</audio>
					<pre className="post__text">{post.message.substring(0, characterLimit) + "..."}</pre>
					<p className="post__expand"
					onClick={() => {setTextExpanded(true)}}
					>{(language === "en") ? "Read more" : "Lire la suite"}</p>
					<p className="post__author">{post.name}</p>
				</div>
			)
		}
	} else {
		return null
	}
}

const PostCard = (props) => {
	const { post, activeCategory, language } = props;

	if (post.image.path) {

		return (
			<ImagePostCard
				post={post}
				activeCategory={activeCategory}
				language={language}
			/>
		)
	} else if (post.video_file || post.video_url) {
		return (
			<VideoPostCard
				post={post}
				activeCategory={activeCategory}
				language={language}
			/>
		)
	} else if (post.sound) {
		return (
			<AudioPostCard
				post={post}
				activeCategory={activeCategory}
				language={language}
			/>
		)
	} else if (post.playlist_embed) {
		return (
			<PlaylistPostCard
				post={post}
				activeCategory={activeCategory}
				language={language}
			/>
		)
	}

	else {
		return (
			<TextPostCard
				post={post}
				activeCategory={activeCategory}
				language={language}
			/>
		);
	}
}

const PostsBoard = (props) => {

	const { posts, activeCategory, language } = props;

	return (
		<div
			className="posts-board">
			{posts.map((post, index) => (
				<PostCard
					post={post}
					key={index}
					activeCategory={activeCategory}
					language={language}
				/>
			))}
		</div>
	);


}

export default PostsBoard