import React, { useEffect} from 'react'
import { Link } from 'react-router-dom'

const TagSelection = (props) => {

	const { language, activeCategory, setActiveCategory } = props;


  	 useEffect(()=>{
	   	const tagBar = document.getElementById("tagbar");
		const sticky = tagBar.offsetTop;
		const scrollCallBack = window.addEventListener("scroll", () => {
		  if (window.pageYOffset > sticky) {
		    tagBar.classList.add("sticky");

		  } else {
		    tagBar.classList.remove("sticky"); 

		  }
		});
		return () => {
		  window.removeEventListener("scroll", scrollCallBack);
		};
	  }, [])

	return (
		<div className="tag-selection__container" id="tagbar">
			<div
				className={`tag-selection__button${(activeCategory === "all") ? ' active' : ''}`}
				onClick={() => {setActiveCategory("all")}}
			>
				<p className="tag-selection__button__text">{(language === "en") ? "All" : "Tout"}</p>
			</div>
			<div
				className={`tag-selection__button${(activeCategory === "text") ? ' active' : ''}`}
				onClick={() => {setActiveCategory("text")}}
			>
				<p className="tag-selection__button__text">{(language === "en") ? "Text" : "Texte"}</p>
			</div>
			<div
				className={`tag-selection__button${(activeCategory === "images") ? ' active' : ''}`}
				onClick={() => {setActiveCategory("images")}}
			>
				<p className="tag-selection__button__text">Images</p>
			</div>
			<div
				className={`tag-selection__button${(activeCategory === "videos") ? ' active' : ''}`}
				onClick={() => {setActiveCategory("videos")}}
			>
				<p className="tag-selection__button__text">{(language === "en") ? "Videos" : "Vidéos"}</p>
			</div>
			<div
				className={`tag-selection__button${(activeCategory === "sounds") ? ' active' : ''}`}
				onClick={() => {setActiveCategory("sounds")}}
			>
				<p className="tag-selection__button__text">{(language === "en") ? "Sound" : "Sonner"}</p>
			</div>
			<Link
				to="/share"
				className="tag-link__button"
				onClick={() => {window.scrollTo(0, 0);}}
				>
				<p className="tag-link__text">{(language === "en") ? "Share" : "Partager"}</p>
			</Link>
		</div>
	)
}

export default TagSelection