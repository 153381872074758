import React from 'react'
import {
	Header,
	TagSelection,
	IntroBox,
	PostsBoard,
	HeaderButtons,
	UploadBox,
	HelpBox
} from '../components/'

const Home = (props) => {

	const { pathname, introText, setIntroActive, introActive, language, setLanguage, activeCategory, setActiveCategory, posts, sharePageData } = props;

	return (
		<div style={{position: introActive || pathname === "/intro" || pathname === "/help" || pathname === "/share" ? 'fixed' : 'static', maxWidth: '100vw'}}>
			<Header
				language={language}
				setLanguage={setLanguage}
				introActive={introActive}
			/>
			<TagSelection
				language={language}
				activeCategory={activeCategory}
				setActiveCategory={setActiveCategory}
			/>
			<HeaderButtons
				language={language}
				setLanguage={setLanguage}
				introActive={introActive}
			/>
			<PostsBoard
				posts={posts}
				activeCategory={activeCategory}
				introActive={introActive}
				language={language}
			/>
			<IntroBox
				introText={introText}
				introActive={introActive}
				setIntroActive={setIntroActive}
				language={language}
				pathname={pathname}
			/>
			<UploadBox
				language={language}
				pathname={pathname}
				sharePageData={sharePageData}
			/>
			<HelpBox
				language={language}
				pathname={pathname}
			/>
		</div>
	);
}

export default Home