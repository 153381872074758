import React from 'react'
import { motion, AnimatePresence } from "framer-motion";
import { Link } from 'react-router-dom'

const IntroBox = (props) => {

	const { pathname, language } = props;

	return (
		<AnimatePresence>
	    {pathname === "/help" && (
	      <motion.div
	      	className="help-box"
	        initial={{ opacity: 0 }}
	        animate={{ opacity: 1 }}
	        exit={{ opacity: 0 }}
	      >

	      	<div className="help-text__container">

	      			<p className="help-text__text">

	      				{language === "en" ? "If you are having trouble using or sharing on this site, please contact us at " : "Si vous rencontrez un souci en utilisant le site, ou en téléchargeant des fichiers, veuillez s’il vous plaît nous écrire à: "}
	      				<a href="mailto:fredadkinslife@gmail.com">fredadkinslife@gmail.com</a>
	      				{language === "en" ? " and we’d be happy to help." : ". Nous sommes là pour vous aider."}
	      			</p>

	      			<div
			      		to="/"
			      		className="intro-link__button"
			      	>
			      			<Link
			      				to="/"
			      				className="intro-link__text"
			      				>
			      				{ (language === "en") ? "Close" : "Fermer"}
			      			</Link>

			      	</div>
	      	</div>




	      </motion.div>
	    )}
	  </AnimatePresence>
	);





}

export default IntroBox