import { useState } from 'react';
import { cockpitUrl } from '../cockpit.js';
import { motion, AnimatePresence } from "framer-motion";
import parse from 'html-react-parser';

const UploadBox = (props) => {

  const { pathname, language, sharePageData } = props;

  const [uploadSuccess, setUploadSuccess] = useState(false);
  const [uploadIsProcessing, setUploadIsProcessing] = useState(false);
  const [uploadFailed, setUploadFailed] = useState(false);
  const [uploadAttempted, setUploadAttempted] = useState(false);
  const [nameValue, setNameValue] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [messageValue, setMessageValue] = useState('');
  const [videoUrlValue, setVideoUrlValue] = useState('');
  const PATH = '/api/cockpit/addAssets?token=';
  const apiKey = process.env.REACT_APP_API_KEY;
  const api = cockpitUrl + PATH + apiKey;

  const resetForm = () => {
    setUploadFailed(false);
    setUploadIsProcessing(false);
    setUploadAttempted(false);
    setNameValue('');
    setEmailValue('');
    setMessageValue('');
    setVideoUrlValue('');
    if (document.querySelector('.upload-box')) {
      document.querySelector('.upload-box').scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }

  const createPost = (assets) => {
    if (assets || nameValue !== '' || emailValue !== '' || messageValue !== '' || videoUrlValue !== '') {

      let assetPath = '';
      let assetType;

      if (assets) {
        const asset = assets[0];

        if (asset) {
          assetPath = '/storage/uploads' + asset.path;
          if (asset.image === true) {
            assetType = 'image';
          }
          if (asset.video === true) {
            assetType = 'video';

            if (asset.mime !== 'video/mp4') {
              assetType = 'wrongformat';
            }
          }
          if (asset.audio === true) {
            assetType = 'audio';
          }
        }
      }

      if (assetType !== 'wrongformat') {
        fetch(`${cockpitUrl}/api/collections/save/post?token=${apiKey}`, {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            data: {
              name: nameValue,
              email: emailValue,
              message: messageValue,
              video_url: videoUrlValue,
              image: assetType === 'image' ? { path: assetPath } : null,
              video_file: assetType === 'video' ? assetPath : null,
              sound: assetType === 'audio' ? assetPath : null,
              title: new Date().toString()
            }
          })
        })
          .then(res => res.json()).then(() => {
            setUploadSuccess(true);
            setUploadFailed(false);
            resetForm();
          })
          .catch(error => {
            console.log(error);
            setUploadFailed(true);
            setUploadIsProcessing(false);
          });
      } else {
        setUploadFailed(true);
        setUploadIsProcessing(false);
      }

    } else {
      console.log('no form data')
    }
  }

  const uploadImage = (e) => {
    e.preventDefault();
    setUploadIsProcessing(true);
    const fileInput = document.querySelector('#uploadFile');
    const formData  = new FormData();

    formData.append('files[]', fileInput.files[0]);

    if (fileInput.files[0]) {
      if (fileInput.files[0].type === 'video/quicktime') {
        setUploadFailed(true);
        return;
      }
    }

    fetch(api, {
      method : 'POST',
      body : formData
    })
    .then(e=>e.json())
    .then(response=>{
      const { assets } = response;
      if (!assets) {
        if (uploadAttempted === true) {
          setUploadFailed(true);
          setUploadIsProcessing(false);
          return;
        } else {
          createPost();
        }
      } else {
        if (!assets[0]) {
          if (uploadAttempted === true) {
            setUploadFailed(true);
            setUploadIsProcessing(false);
            return;
          } else {
            createPost();
          }
        } else {
          createPost(assets);
        }
      }
    })
  }

  return (
    <AnimatePresence>
      {
        pathname === "/share" && (
          <motion.div
            className={`upload-box${uploadIsProcessing === true ? ' processing' : ''}`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
        >
          <form
            onSubmit={uploadImage}
          >
          <div className="upload-box__intro">
            {
              language === 'en' ?
                sharePageData.english ?
                  parse(sharePageData.english) : ''
                :
                sharePageData.french ?
                  parse(sharePageData.french) : ''
            }
          </div>
            {
              uploadSuccess === true &&
                <div className="upload-form__success">{language === 'en' ? sharePageData.upload_success_english ? parse(sharePageData.upload_success_english) : '' : sharePageData.upload_success_french ? parse(sharePageData.upload_success_french) : ''}</div>
            }
            {
              uploadFailed === true &&
                <div className="upload-form__error">{language === 'en' ? sharePageData.upload_failed_english ? parse(sharePageData.upload_failed_english) : '' : sharePageData.upload_failed_french ? parse(sharePageData.upload_failed_french) : ''}</div>
            }
              <label className="upload-form__label" htmlFor="name">{language === 'fr' ? 'Votre nom (facultatif)' : 'Your name (optional)'}</label>
              <input className="upload-form__input" type="text" name="name" value={nameValue} onChange={(e) => (setNameValue(e.target.value))} />
              <label className="upload-form__label" htmlFor="email">{language === 'fr' ? 'Votre email (facultatif)' : 'Your email (optional)'}</label>
              <input className="upload-form__input" type="email" name="email" value={emailValue} onChange={(e) => { setEmailValue(e.target.value) }} />
              <label className="upload-form__label" htmlFor="message">{language === 'fr' ? 'Votre message (facultatif)' : 'Your message (optional)'}</label>
              <textarea className="upload-form__message" rows="6" name="message" value={messageValue} onChange={(e) => { setMessageValue(e.target.value) }} />
              <label className="upload-form__label" htmlFor="file">{language === 'fr' ? 'Votre ficher (facultatif)' : 'Your file (optional)'}</label>
              <input className="upload-form__file" type="file" name="file" id="uploadFile" onChange={() => { setUploadAttempted(true) }} />
              <label className="upload-form__label" htmlFor="video URL">{language === 'fr' ? 'Lien URL de la vidéo (si elle est déjà sur YouTube ou Vimeo)' : 'Your video URL (if you are sharing a video using Youtube or Vimeo)'}</label>
              <input
                className="upload-form__input"
                accept="image/png, image/jpg, image/jpeg, image/gif, video/mp4, audio/mp3, audio/mp4, audio/m4a"
                type="text"
                name="video URL"
                value={videoUrlValue}
                onChange={(e) => { setVideoUrlValue(e.target.value) }}
              />
              <input type="submit" name="submit" className="upload" value={language === 'en' ? 'Upload' : 'Télécharger'}></input>
              <br />
            </form>
          </motion.div>
        )}
     </AnimatePresence>
  )
}

export default UploadBox;