import React, { Fragment } from 'react'
import '../scss/main.scss';
import { useState, useEffect } from 'react';
import { Route } from 'react-router-dom';
import { cockpitUrl } from './cockpit.js';
import Home from './views/Home.js'

const Content = (props) => {

  const [introActive, setIntroActive] = useState(true);
  const [introText, setIntroText] = useState({});
  const [language, setLanguage] = useState("en");
  const [activeCategory, setActiveCategory] = useState("all");
  const [postsData, setPostsData] = useState();
  const [sharePageData, setSharePageData] = useState({});


  const [windowDimensions, setWindowDimensions] = useState({
    windowWidth: window.innerWidth,
    windowHeight: window.innerHeight
  });

  const { pathname } = props.location;

  useEffect(() => {

    const handleResize = () => {
      setWindowDimensions({
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight
      });
    }

    const fetchIntroData = () => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/singletons/get/intro?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setIntroText(response);
      })
      .catch(error => {
        console.log(error);
      })
    }

    const fetchSharePageData = () => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/singletons/get/share_page?token=${apiKey}`)
      .then(response => response.json())
      .then(response => {
        setSharePageData(response);
      })
      .catch(error => {
        console.log(error);
      })
    }

    const fetchPostsData = () => {
      const apiKey = process.env.REACT_APP_API_KEY;
      fetch(`${cockpitUrl}/api/collections/get/post?token=${apiKey}`)
      .then(response => response.json())
        .then(response => {
        setPostsData(response);
      })
      .catch(error => {
        console.log(error);
      })
    }

    fetchIntroData();
    fetchSharePageData();
    fetchPostsData();

    setWindowDimensions({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight
    });
    let resizeTimeout;
    const setWindowResizeTimeout = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(
        handleResize, 200
      )
    }

    return () => {
      clearTimeout(resizeTimeout);
      window.removeEventListener('resize', setWindowResizeTimeout);
    }
  }, [pathname]);

  if (introText && postsData) {
    return (
      <Fragment>
        <Home
          pathname={pathname}
          introText={introText}
          introActive={introActive}
          sharePageData={sharePageData}
          setIntroActive={setIntroActive}
          language={language}
          setLanguage={setLanguage}
          windowDimensions={windowDimensions}
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          posts={postsData.entries.reverse()}
        />
      </Fragment>
    )
  } else {
    return null
  }

}

const App = () => {
  return (
    <Fragment>
      <Route path="/" render={
        (props) => (
          <Content {...props} />
        )
      } />
    </Fragment>
  );
}

export default App;
