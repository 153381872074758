import React from 'react'
import { Link } from 'react-router-dom'

const HeaderButtons = (props) => {

	const { language, setLanguage } = props;

	const toggleLanguage = () => {
		if (language === "en") {
			setLanguage("fr");
		} else {
			setLanguage("en");
		}
	}

	return (


		<div className="header-links__container">
			<div
				className="header-link__button"
				onClick={toggleLanguage}
				>
				<p className="header-link__text">{(language === "en") ? "Français" : "English"}</p>
			</div>
			<Link
				to="/intro"
				className="header-link__button"
				>
				<p className="header-link__text">Intro</p>
			</Link>
			<Link
				to="/help"
				className="header-link__button"
				>
				<p className="header-link__text">{(language === "en") ? "Help" : "Aider"}</p>
			</Link>
			
			<Link
				to="/share"
				className="header-link__button"
				>
				<p className="header-link__text">{(language === "en") ? "Share" : "Partager"}</p>
			</Link>
		</div>


	)
}

export default HeaderButtons